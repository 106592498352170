.niche-page {
    padding: 2rem;
}

.niche-page h1 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.niche-page span {
    display: block;
    color: #aaa;
    margin-bottom: 1rem;
}

.niche-page p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
    color: #333;
}

.pills {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.pills a {
    padding: 0.5rem 1rem;
    background: #333;
    color: white;
    text-decoration: none;
    border-radius: 20px;
}

.pills a:hover {
    background: #555;
}

/* Mobile styles */
@media (max-width: 768px) {
    .niche-page {
        padding: 1rem;
    }

    .niche-page h1 {
        font-size: 1.5rem;
    }

    .niche-page p {
        font-size: 0.875rem;
    }

    .pills {
        flex-direction: column;
    }
}
