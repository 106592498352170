/* ProductsPage.css */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

/* Make the root a flex container */
.products {
    display: flex;
    flex-direction: column;
    min-height: 100vh;  /* Ensure it takes full viewport height */
}

.products-page {
    flex: 1;  /* This will make it grow and take all available space */
    background-color: #eaeaea;
    padding: 20px 0;
}

.products-page {
    background-color: #eaeaea;
    padding: 20px 0;
}

.productCard {
    display: flex;
    border: 1px solid #e0e0e0;
    padding: 15px;
    margin: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f8f8f8;
}

.product-name {
    font-size: 20px;
}

.imageSection {
    width: 30%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageWrapper1 {
    width: 100%;
}

.productImage {
    max-width: 100%;
    object-fit: contain;
    border-radius: 10px;
}

.detailsSection {
    width: 70%;
    padding: 10px;
    box-sizing: border-box;
}

.specifications ul {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    padding-left: 0;
}

.specifications li {
    list-style: disc inside;
    margin: 5px 10px;
    width: auto;
}

.whoShouldBuy {
    font-size: 16px;
    line-height: 1.5;
    margin: 10px 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.btn-buy {
    display: inline-block;
    background-color: #26a182;
    color: #fff;
    text-align: center;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
}

.product-loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
}

.imageWrapper1.loading {
    position: relative;
}

.imageWrapper1.loading::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

.productImage {
    visibility: hidden; /* Initially hide the image */
}

.imageWrapper1:not(.loading) .productImage {
    visibility: visible; /* Show the image when it has loaded */
}

.intro-section {
    padding: 10px;
    background-color: #f1f1f1; /* Light gray background */
    margin: 10px 0;
    border-radius: 5px;
    font-style: italic; /* To give it a distinctive blog-like feel */
}

.products-header-line {
    margin-left: 20px;
}

.Analysis {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .productCard {
        flex-direction: column;
    }

    .imageSection,
    .detailsSection {
        width: 100%;
    }

    .specifications ul {
        flex-direction: column;
    }

    .specifications li {
        width: 100%;
    }
}
