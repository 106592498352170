.carousel {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    background: #f0f0f0;
}

.carousel-item {
    position: relative;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px; /* adjust this value */
}

.carousel-image {
    max-width: 100%;
    height: auto;
}

.carousel-caption {
    margin-top: 10px;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    color: white;
    border-radius: 5px;
}

.slick-next:before, .slick-prev:before {
    color: black;
}

.slick-next, .slick-prev {
    z-index: 1;
    margin: 0 10px;
}

/* Mobile styles */
@media (max-width: 768px) {
    .slick-next, .slick-prev {
        margin: 0 20px;
    }

    .carousel-item {
        height: 300px; /* adjust this value */
        text-decoration: none;
    }

    .carousel-image {
        height: 200px;
        background: #aa9292;
        width: 80%;
    }

    .carousel-link {
        text-decoration: none;
        color: white;
    }
}

/* Existing styles ... */

/* Desktop styles */
@media (min-width: 769px) {
    .carousel-item {
        height: 400px; /* Decreased from 400px for a more proportionate look */
    }

    .carousel-image {
        width: 20%; /* Set a maximum width for the image so it doesn't stretch too wide */
        height: auto; /* Make sure the image retains its aspect ratio */
        max-height: 230px; /* Set a maximum height for the image to fit within the carousel item */
    }

    .carousel-caption {
        font-size: 1.1em; /* Slightly larger font size for desktop */
    }

    .carousel-link {
        text-decoration: none;
        color: white;
    }
}
