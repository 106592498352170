.header {
    background: #49B029;
    color: white;
    padding: 0.5rem;
}

.header-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-logo img {
    height: 30px;
    width: 250px;
}

.header-action-bar a {
    color: white;
    font-weight: bold;
    text-decoration: none;
    padding: 0 1rem;
}

/* Mobile styles */
@media (max-width: 768px) {
    .header-logo img {
        height: 25px;
        width: 180px;
    }

    .header-nav-toggle svg {
        height: 30px;
        width: 30px;
    }

    .header-action-bar {
        display: none;
    }
}

/* Desktop styles */
@media (min-width: 769px) {
    .header-nav-toggle {
        display: none;
    }
}

.header-nav-toggle {
    background: none;
    border: none;
    color: white;
}

.header-menu {
    position: absolute;
    top: 5%;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 1000; /* Add this line */
}



.header-menu a {
    display: block;
    padding: 10px 20px;
    color: #333;
    text-decoration: none;
}

.header-menu a:hover {
    background-color: #f0f0f0;
}

.header-menu.open {
    display: block; /* other styles */
}
