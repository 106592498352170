.homepage {
    /* Add any styling needed for the entire homepage here */
}

.hero-section {
    background: 
        linear-gradient(rgba(25, 119, 90, 0.9), rgba(25, 119, 90, 0.9)), 
        url('../public/images/back-hero.png') no-repeat center center fixed;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    padding: 20px;
}

.hero-section h2 {
    font-size: 1.4em;
    margin-bottom: 10px;
    max-width: 800px; /* Ensuring the width doesn't stretch too much on wider screens */
}

.hero-section p {
    font-size: 0.9em;
    max-width: 600px; /* Ensuring the width doesn't stretch too much on wider screens */
    margin-top: 10px;
}

/* Adjustments for larger screens */
@media (min-width: 768px) {
    .hero-section h2 {
        font-size: 1.8em;
    }
    
    .hero-section p {
        font-size: 1.1em;
    }
}

.telegram-cta {
    display: inline-block;
    margin-top: 5px;
    padding: 10px 20px;
    font-size: 1em;
    background-color: #0088cc; /* Telegram color */
    border-radius: 5px;
    text-decoration: none;
    color: white;
    transition: background-color 0.2s;

    /* Shadow for better standout */
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.telegram-cta:hover {
    background-color: #006fa1; /* Slightly darker shade on hover */
}




.info-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    margin-left: 20px;
}

.info-item {
    width: 35%;
    background-color: #f8f8f8;
    border: 1px solid #e0e0e0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    margin-bottom: 20px;
}


.info-item .imageWrapper {
    position: relative;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    background: #e0e0e0;
}

.info-item .imageWrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.info-item h2 {
    font-size: 1.5rem;
    margin: 10px;
    color: #333;
}

@media (min-width: 769px) {
    .info-section {
        justify-content: space-around; /* Distribute items evenly with equal space around them */
        padding: 0 20px; /* Padding to the left and right to prevent items from sticking to the edge */
    }

    .info-item {
        width: calc(33.33% - 20px); /* Divide by 3 for three items per row, subtracting for margin */
        flex-direction: column; /* Stack the image and the text vertically */
        align-items: center; /* Align items in the center */
        padding: 20px 0; /* Padding at the top and bottom */
        transition: transform 0.3s; /* For hover effect */
        border-radius: 5px; /* Optional: gives a slight curve to corners */
    }

    .info-item:hover {
        transform: scale(1.05); /* On hover, slightly scale up the item for an interactive feel */
    }

    .info-item .imageWrapper {
        width: 100%; /* Take full width */
        height: 150px; /* Fixed height for images */
        position: relative;
        overflow: hidden; /* Ensure that images don't spill out of the container */
    }

    .info-item .imageWrapper img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        object-fit: cover;
        border-radius: 5px; /* Optional: gives a slight curve to image corners */
    }

    .nicheText {
        margin-top: 10px; /* Space between the image and the text */
    }
}

/* Mobile styles */
@media (max-width: 768px) {
    .info-section {
        margin-left: 0;
        justify-content: center;
    }
    .info-item {
        width: 45%;
        border-radius: 10px;
    }
    .nicheText {
        font-size: 14px;
        margin-left: 5px;
        color: black;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .imageWrapper {
        height: 25px;
    }
}



/* Mobile styles */
@media (max-width: 768px) {
    .hero-section {
        height: 28vh;
    }

    /* .info-section {
        flex-direction: column;
        align-items: center;
    }

    .info-item {
        width: 100%;
    } */
}
