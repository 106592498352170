.contact-page {
  background-position: center;
  background-size: cover;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url('../public/images/background.jpg'); 
  background-repeat: no-repeat;
  background-size: cover;
  color: #FFFFFF
}

.contact-section {
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  padding: 2% 20px;
}

.contact-header-text {
  text-align: center;
  color: #66CCCC;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.contact-form {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.contact-input-group {
  margin-bottom: 15px;
}

.contact-input-box.form-control {
  padding: 10px;
  width: 80%;
}

.contact-form-label {
  margin-bottom: 5px;
  font-weight: bold;
}

.contact-btn-send {
  padding: 12px 20px;
  font-weight: bold;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.contact-btn-send:hover {
  background-color: #449999;
}

@media (min-width: 768px) {
  .contact-section {
    padding: 50px;
  }
}
