body, html, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  background: #333;
  color: white;
  padding: 1rem;
}

main {
  flex: 1;
}

.footer {
  background: rgb(26, 161, 130);
  padding: 20px 0;
  color: #fff;
  text-align: center;
  width: 100%;
}
